import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import OptionalLink from "../contentTemplates/optionalLink"

const Fullscreen = ({
  amenity_title,
  amenity_text,
  link,
  link_label,
  image,
}) => {
  return (
    <Container fluid={image.fluid}>
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: amenity_title.html }} />
        <Text color="#fff">{amenity_text.text}</Text>
        {!link.link_type || link.link_type === "Any" ? null : (
          <OptionalLink
            url={link.url}
            type={link.link_type}
            text={link_label}
          />
        )}
      </Content>
    </Container>
  )
}

export default Fullscreen

const Container = styled(BackgroundImage)`
  padding: 0 0 40px 40px;
  height: 320px;
  display: flex;
  align-items: flex-end;
  p {
    font-size: 1rem;
  }
  @media (max-width: 600px) {
    height: 300px;
    padding: 24px;
  }
  grid-column-start: 1;
  grid-column-end: 4;
`
const Content = styled.div`
  max-width: 513px;
`
const Title = styled.div`
  h2 {
    color: #fff;
    text-transform: capitalize;
  }
`
const Text = styled.p`
  color: #fff;
`
