import React from "react"
import styled from "styled-components"

const SimpleVideo = ({ title, text, video }) => {
  return (
    <Container>
      <VideoHeader>
        <Title dangerouslySetInnerHTML={{ __html: title.html }} />
        <Text dangerouslySetInnerHTML={{ __html: text.html }} />
      </VideoHeader>
      <Iframe loading="lazy" src={video.url} frameBorder={0} />
    </Container>
  )
}

export default SimpleVideo

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 16px 120px 16px;
  width: 60%;
  margin: 0 auto;
  @media (max-width: 850px) {
    width: 80%;
    padding: 40px 0 120px 0;
    iframe {
      height: 350px;
    }
  }
`

const VideoHeader = styled.div`
  text-align: center;
  color: var(--primary-text);
  padding: 16px;
  margin-bottom: 24px;
`
const Title = styled.div`
  h2 {
    text-align: center;
  }
`
const Text = styled.div``
const Iframe = styled.iframe`
  height: 500px;
`
