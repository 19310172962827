import React from "react"
import Layout from "../components/layout/layout"
import { useAmenitiesPageData } from "../hooks/useAmenitiesPageData"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"
import Amenities from "../components/amenities"
import { withPreview } from "gatsby-source-prismic"
import SimpleVideo from "../components/amenities/SimpleVideo"

const AmenitiesPage = ({ data: previewData }) => {
  const { uid, header, amenities, simpleVideo, bookData } =
    useAmenitiesPageData(previewData)
  return (
    <Layout bookBannerData={bookData}>
      <SEO title={uid} />
      <PageHeader {...header} />
      <Amenities amenities={amenities} />
      <SimpleVideo {...simpleVideo} />
    </Layout>
  )
}

export default withPreview(AmenitiesPage)
