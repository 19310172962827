import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useAmenitiesPageData = previewData => {
  const { prismicAmenitiesPage } = useStaticQuery(graphql`
    query {
      prismicAmenitiesPage {
        _previewable
        uid
        data {
          body {
            ... on PrismicAmenitiesPageBodyHeader {
              id
              slice_type
              primary {
                title {
                  html
                }
                text {
                  text
                }
                tagline {
                  text
                }
              }
            }
            ... on PrismicAmenitiesPageBodyAmenities {
              id
              slice_type
              items {
                document_pdf {
                  url
                }
                document_label
                span
                amenity_title {
                  html
                }
                amenity_text {
                  text
                }
                link_type
                link_label
                link {
                  url
                  link_type
                }
                image {
                  fluid(maxHeight: 410, maxWidth: 600) {
                    aspectRatio
                    sizes
                    src
                    srcSet
                  }
                  fixed(height: 410, width: 580) {
                    src
                    srcSet
                    width
                    height
                  }
                  alt
                }
              }
            }
            ... on PrismicAmenitiesPageBodyBook {
              id
              slice_type
              primary {
                text {
                  text
                }
                link {
                  url
                  link_type
                }
                button_text
              }
            }
            ... on PrismicAmenitiesPageBodySimpleVideo {
              id
              slice_type
              primary {
                title {
                  html
                }
                text {
                  html
                }
                video {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  const mergedData: any = mergePrismicPreviewData({
    staticData: prismicAmenitiesPage,
    previewData,
  })
  const { uid, data } = mergedData
  const header = data.body.find(
    section => section.slice_type === "header"
  ).primary
  const amenities = data.body.find(
    section => section.slice_type === "amenities"
  ).items
  const simpleVideo = data.body.find(
    section => section.slice_type === "simple_video"
  ).primary
  const bookData = data.body.find(section => section.slice_type === "book")
  return {
    uid,
    header,
    amenities,
    simpleVideo,
    bookData: bookData ? bookData.primary : undefined,
  }
}
