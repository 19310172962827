import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import { Text } from "../atoms/text"
import { Container, ImageContainer, Title } from "./ImageRight"
import OptionalLink from "../contentTemplates/optionalLink"

const ImageLeft = ({
  amenity_title,
  amenity_text,
  link,
  link_label,
  image,
}) => {
  return (
    <Container>
      <ImageContainer>
        <Img fluid={image.fluid} alt={image.alt || "amenity"} />
      </ImageContainer>
      <Content>
        <div>
          <Title dangerouslySetInnerHTML={{ __html: amenity_title.html }} />
          <Text>{amenity_text.text}</Text>
        </div>
        {!link.link_type || link.link_type === "Any" ? null : (
          <OptionalLink
            url={link.url}
            type={link.link_type}
            text={link_label}
          />
        )}
      </Content>
    </Container>
  )
}

export default ImageLeft

const Content = styled.div`
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 750px) {
    padding-left: 0;
  }
`
