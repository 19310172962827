import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { Title } from "./ImageRight"
import { Text } from "../atoms/text"
import OptionalLink from "../contentTemplates/optionalLink"

const OneThirdScreen = ({
  amenity_title,
  amenity_text,
  link,
  link_label,
  image,
}) => {
  return (
    <Container>
      <Img fluid={image.fluid} alt={image.alt || "amenity"} />
      <Title dangerouslySetInnerHTML={{ __html: amenity_title.html }} />
      <Text>{amenity_text.text}</Text>
      {!link.link_type || link.link_type === "Any" ? null : (
        <OptionalLink url={link.url} type={link.link_type} text={link_label} />
      )}
    </Container>
  )
}

export default OneThirdScreen

const Container = styled.div`
  display: grid;
  grid-template-rows: 300px auto 1fr auto;
  .gatsby-image-wrapper {
    height: 100%;
  }
  @media (max-width: 750px) {
    grid-column: 1 / span 3;
  }
`
